import React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';


const AFRLCaseStudy: React.FC = () => {
  return (
    <Box mt={8} mb={8} p={4} style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'justify' }}>
      <Typography variant="h4" py={1} align="center" gutterBottom>
        FEMORPH Case Study: AFRL's Research into and As-Manufactured Modeling
      </Typography>

      <Box sx={{ float: 'right', marginLeft: 2, marginTop: 1, width: '300px' }}>
        <img
          src="/case_studies/teff_3d_scanner.png"
          alt="ATOS Full Field 3D Scanner"
          style={{ width: '100%', borderRadius: '8px', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)' }}
        />

        <Typography variant="body2" sx={{ marginTop: 1 }} style={{ textAlign: 'center' }}>
          AFRL Full Field 3D Scanner {' '}
          <a
            href="#ref1"
            style={{ textDecoration: 'none', color: '#3f51b5' }}
          >
            [1]
          </a>

        </Typography>

      </Box>

      <Typography variant="body1" paragraph>
        Developed by the Air Force Research Laboratory (
        <a
          href="https://www.afrl.af.mil/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none', color: '#3f51b5' }}
        >
          AFRL
        </a>
        ), FEMORPH is a mesh metamorphosis tool designed to generate
        high-fidelity, as-manufactured finite element models (FEMs) directly
        from 3D scan data. This software eliminates the dependency on
        time-intensive CAD processes, enabling rapid and accurate modeling of
        complex geometries with applications across academic, military, and
        commercial domains. By utilizing geometry collected from full field
        measurement systems, including the ATOS 3D scanner at the Turbine
        Engine Fatigue Facility (TEFF), FEMORPH supports precision research and
        validation efforts critical to aerospace and defense.
      </Typography>

      <Typography variant="h4" py={2} gutterBottom>
        Developmental Research
      </Typography>


      <Typography variant="body1" paragraph>
        FEMORPH uses a variety of advanced algorithms to automatically update
        the geometry of an "as-designed" finite element model based on nominal
        CAD to match real-world as-manufactured geometry. The figures below
        demonstrate the typical workflow whereby an initial FEM and surface
        geometry are loaded into FEMORPH, resulting in a final as-manufactured
        model. This is both accurate and automatic without relying on costly
        manual steps.
      </Typography>

      <Box pb={4} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
          <Box sx={{ width: '32%' }}>
            <img
              src="/case_studies/afrl-disk-0.webp"
              alt="AFRL Disk 0"
              style={{ width: '100%', borderRadius: '8px', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)' }}
            />
            <Typography variant="body2" sx={{ marginTop: 1 }} style={{ textAlign: 'center' }}>
              As-Designed Finite Element Model (FEM)
            </Typography>
          </Box>
          <Box sx={{ width: '32%' }}>
            <img
              src="/case_studies/afrl-disk-1.webp"
              alt="AFRL Disk 1"
              style={{ width: '100%', borderRadius: '8px', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)' }}
            />
            <Typography variant="body2" sx={{ marginTop: 1 }} style={{ textAlign: 'center' }}>
              Full Field 3D Scan
            </Typography>
          </Box>
          <Box sx={{ width: '32%' }}>
            <img
              src="/case_studies/afrl-disk-2.webp"
              alt="AFRL Disk 2"
              style={{ width: '100%', borderRadius: '8px', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)' }}
            />
            <Typography variant="body2" sx={{ marginTop: 1 }} style={{ textAlign: 'center' }}>
              Final As-Manufactured FEM
            </Typography>
          </Box>
        </Box>
      </Box>

      <Typography variant="body1" paragraph>

        The fidelity of the FEMORPH algorithm has been demonstrated in several peer reviewed articles including {' '}
        <a
          href="#ref1"
          style={{ textDecoration: 'none', color: '#3f51b5' }}
        >
          [1]
        </a>, {' '}
        <a
          href="#ref2"
          style={{ textDecoration: 'none', color: '#3f51b5' }}
        >
          [2]
        </a>, and {' '}
        <a
          href="#ref3"
          style={{ textDecoration: 'none', color: '#3f51b5' }}
        >
          [3]
        </a>. The research quantified measurement uncertainties, showing average
        deviations of 0.0002 inches (5 μm), with negligible impacts on
        low-to-mid frequency modal responses. When applied to high cycle
        fatigue prediction, despite the sensitivity of GMMs to geometric data
        quality, MORPH exhibits reliability in generating FEMs with virtually
        no numerical mistuning.

      </Typography>


      <Typography variant="h4" py={2} gutterBottom>
        Applied Research
      </Typography>

      FEMORPH has been applied to a wide variety of problems, from characterizing
      high cycle fatigue of as manufactured integrally bladed rotors to
      predicting the variation of blade tip timing limits from non-intrusive
      stress measurement. Here are a few research categories FEMORPH has been
      applied to:

      {/* New */}

      <Typography variant="h5" py={2} gutterBottom>
        Mistuning Prediction through As Manufactured Modeling
      </Typography>

      <Box sx={{ float: 'left', marginRight: 2, marginTop: 1, width: '300px' }}>
        <img
          src="/case_studies/mistuning-1b.webp"
          alt="Blade-Specific Blend Repair"
          style={{ width: '100%', borderRadius: '8px', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)' }}
        />
        <Typography variant="body2" sx={{ marginTop: 1 }} style={{ textAlign: 'center' }}>
          Tuned and Mistuned 1st Bend Response of an IBR {' '}
          <a
            href="#ref8"
            style={{ textDecoration: 'none', color: '#3f51b5' }}
          >
            [8]
          </a>
        </Typography>

      </Box>

      <Typography variant="body1" paragraph>
        FEMORPH has been demonstrated to accurately predicting mistuning
        through the creation of geometrically mistuned models. These models
        have been verified experimentally through traveling wave excitation
        within the TEFF using direct modeling as well as reduced order modeling
        via the fundamental mistuning model (FMM). Key research findings
        include addressing alignment sensitivity, point cloud noise, and scan
        bias, demonstrating the robustness of MORPH across variable
        conditions.{' '}
        <a
          href="#ref4"
          style={{ textDecoration: 'none', color: '#3f51b5' }}
        >
          [4]
        </a>

      </Typography>

      <Typography variant="h5" py={2} gutterBottom>
        Blade-Specific Blend Repair Limits
      </Typography>

      <Box sx={{ float: 'right', marginLeft: 2, marginTop: 1, width: '200px' }}>
        <img
          src="/case_studies/blend-repair.webp"
          alt="Blade-Specific Blend Repair"
          style={{ width: '100%', borderRadius: '8px', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)' }}
        />
        <Typography variant="body2" sx={{ marginTop: 1 }} style={{ textAlign: 'center' }}>
          Higher Order Mode shape of a Blended Airfoil {' '}
          <a
            href="#ref6"
            style={{ textDecoration: 'none', color: '#3f51b5' }}
          >
            [6]
          </a>
        </Typography>

      </Box>

      <Typography variant="body1" paragraph>
        Research identified significant variation between the structural
        response resulting from geometric manufacturing deviations. AFRL
        demonstrated, using FEMORPH, that these variations lead to substantial
        differences in allowable blend limits when repairing damaged
        airfoils. This research demonstrated a novel approach to determine each
        airfoil's blend repair capacity using a parametric blended blade FEM
        to account for manufacturing geometry variations and variable blend
        geometry.
      </Typography>

      <Typography variant="body1" paragraph>
        Despite complex modal behavior caused by eigenvalue veering, the
        proposed optimization approach converges. The developed methodologies
        may be used in the future to extend blend limits, enable continued
        operations, and reduce sustainment costs.

        <a
          href="#ref6"
          style={{ textDecoration: 'none', color: '#3f51b5' }}
        >
          [6]
        </a>
      </Typography>

      <Typography variant="h5" py={2} gutterBottom>
        Frequency and Mode Shape Emulation
      </Typography>

      <Box sx={{ float: 'left', marginRight: 2, marginTop: 1, width: '300px' }}>
        <img
          src="/case_studies/mode-shape-emulation.webp"
          alt="Mode Shape Emulation"
          style={{ width: '100%', borderRadius: '8px', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)' }}
        />
        <Typography variant="body2" sx={{ marginTop: 1 }} style={{ textAlign: 'center' }}>
          Frequency & Mode Shape Variation using the First Principal Component{' '}
          <a
            href="#ref7"
            style={{ textDecoration: 'none', color: '#3f51b5' }}
          >
            [7]
          </a>
        </Typography>

      </Box>

      <Typography variant="body1" paragraph>
        FEMORPH was used to create a reduced-order model employing principal
        component analysis (PCA) to emulate frequency and mode shape variations
        in transonic rotors{' '}
        <a
          href="#ref7"
          style={{ textDecoration: 'none', color: '#3f51b5' }}
        >
          [7]
        </a>. The rotors were measured with a structured blue
        light scanner, which provided a dense geometric representation of the
        as-manufactured part. PCA was applied to create a reduced order,
        orthogonal basis of parameters used as independent emulator parameters.
      </Typography>

      <Typography variant="body1" paragraph>
        Geometric variations cause significant variation in
        modal behavior for closely spaced frequencies. The research
        identified that as-manufactured geometry deviations can lead to
        frequency veering and associated chaotic modal behavior.
      </Typography>


      <Typography variant="h5" py={2} gutterBottom>
        Damaged Blade Mesh Morphing
      </Typography>

      <Box sx={{ float: 'right', marginLeft: 2, marginTop: 1, width: '300px' }}>
        <img
          src="/case_studies/damaged-blade.webp"
          alt="Damaged Airfoil"
          style={{ width: '100%', borderRadius: '8px', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)' }}
        />
        <Typography variant="body2" sx={{ marginTop: 1 }} style={{ textAlign: 'center' }}>
          Digital Twin of a Damaged Airfoil
        </Typography>

      </Box>

      <Typography variant="body1" paragraph>
        AFRL collaborated with a small business and University on the
        development of predictive models for damaged airfoil hardware. This
        Small Business Technology Transfer (STTR) Effort was awarded a Phase II
        effort that led to the ability to accurately measure the response of
        damage hardware during engine operation. This capability enables
        predictive maintenance of fielded engines and increases time-on-wing
        and associated lower costs of operation.
      </Typography>

      <Box sx={{ clear: 'both' }} />

      <Typography variant="h5" py={2} gutterBottom>
        Additive Impeller Digital Twins through Mesh Morphing
      </Typography>

      <Box sx={{ float: 'left', marginRight: 2, marginTop: 1, width: '300px' }}>
        <img
          src="/case_studies/additive-impeller-scan.webp"
          alt="Additive Impeller 3D Scan"
          style={{ width: '100%', borderRadius: '8px', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)' }}
        />
        <Typography variant="body2" sx={{ marginTop: 1 }} style={{ textAlign: 'center' }}>
          Additive Impeller 3D Scan
        </Typography>

      </Box>

      <Typography variant="body1" paragraph>
        Additive manufacturing technologies are having a significant impact the design of new and innovative commercial products. Within the turbine engine industry, companies such as {' '}
        <a
          href="https://www.beehive-industries.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none', color: '#3f51b5' }}
        >
          Beehive Inc.
        </a> {' '}
        create almost fully additive engines. AFRL has pursued their own design
        of low-cost additive components with success through the {' '}
        <a
          href="https://www.wpafb.af.mil/News/Article-Display/Article/2011131/afrl-tests-in-house-rapidly-developed-small-engine/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none', color: '#3f51b5' }}
        >
          Responsive Open Source Engine (ROSE)
        </a>
        . One challenge that all additive parts is conformance to the intended
        design geometry. Multiple processing steps may be necessary in the
        additive manufacturing, and each may lead to unintended geometric
        distortions. Measurement of the geometries during the multi-stop
        process and creating predictive models with FEMORPH can lead increase
        the acceptable range of variations and result in lower cost additive
        parts.
      </Typography>

      
      {/* Done New */}


      <Typography variant="h4" py={1} gutterBottom>
        References
      </Typography>
      <List>
        <ListItem id="ref1" style={{ display: 'list-item', marginLeft: '-14px' }}>
          [1] Kaszynski, A. A., Beck, J. A., & Brown, J. M. (2013). Uncertainties of an Automated Optical 3D Geometry Measurement, Modeling, and Analysis Process for Mistuned Integrally Bladed Rotor Reverse Engineering. {' '}
          <a
            href="http://dx.doi.org/10.1115/1.4025000"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: '#3f51b5' }}
          >
            Journal of Engineering for Gas Turbines and Power, 135(10), 102504.
          </a>
        </ListItem>
        <ListItem id="ref2" style={{ display: 'list-item', marginLeft: '-14px' }}>
          [2] Kaszynski, A. A., Beck, J. A., & Brown, J. M. (2014). Automated Finite Element Model Mesh Updating Scheme Applicable to Mistuning Analysis. {' '}
          <a
            href="http://dx.doi.org/10.1115/GT2014-26925"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: '#3f51b5' }}
          >
            ASME Turbo Expo 2014.
          </a>
        </ListItem>
        <ListItem id="ref3" style={{ display: 'list-item', marginLeft: '-14px' }}>
          [3] Kaszynski, A., Brown, J., & Beck, J. (2015). Experimental Validation of an Optically Measured Geometric Mistuning Model Using a System ID Approach. {' '}
          <a
            href="https://asmedigitalcollection.asme.org/GT/proceedings-abstract/GT2015/56765/V07AT27A005/238236"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: '#3f51b5' }}
          >
            ASME Turbo Expo 2015.
          </a>
        </ListItem>

        <ListItem id="ref4" style={{ display: 'list-item', marginLeft: '-14px' }}>
          [4] Kaszynski, A. A., Beck, J. A., & Brown, J. M. (2015). Experimental Validation of a Mesh Quality Optimized Morphed Geometric Mistuning Model. {' '}
          <a
            href="http://dx.doi.org/10.1115/GT2015-43150"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: '#3f51b5' }}
          >
            ASME Turbo Expo 2015, V07AT27A005.
          </a>
        </ListItem>

        <ListItem id="ref5" style={{ display: 'list-item', marginLeft: '-14px' }}>
          [5] Gillaugh, D. L., Kaszynski, A. A., Brown, J. M., Beck, J. A., & Slater, J. C. (2018). Mistuning Evaluation Comparison via As-Manufactured Models, Traveling Wave Excitation, and Compressor Rigs. {' '}
          <a
            href="http://dx.doi.org/10.1115/GT2018-76888"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: '#3f51b5' }}
          >
            ASME Turbo Expo 2018, V07CT35A039.
          </a>
        </ListItem>
        <ListItem id="ref6" style={{ display: 'list-item', marginLeft: '-14px' }}>
          [6] Brown, J. M., Kaszynski, A. A., Gillaugh, D. L., Carper, E. B., & Beck, J. A. (2020). Optimization of Airfoil Blend Limits With As-Manufactured Geometry Finite Element Models. {' '}
          <a
            href="https://doi.org/10.1115/GT2020-15481"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: '#3f51b5' }}
          >
            ASME Turbo Expo 2020: Turbomachinery Technical Conference and Exposition, V011T30A029.
          </a>
        </ListItem>

        <ListItem id="ref7" style={{ display: 'list-item', marginLeft: '-14px' }}>
          [7] Brown, J. M., Carper, E. B., Beck, J. A., & Kaszynski, A. A. (2019). Emulation of As-Manufactured Transonic Rotor Airfoil Modal Behavior and the Significance of Frequency Veering. {' '}
          <a
            href="https://doi.org/10.1115/GT2019-91670"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: '#3f51b5' }}
          >
            ASME Turbo Expo 2019: Turbomachinery Technical Conference and Exposition, V07BT35A023.
          </a>
        </ListItem>

        <ListItem id="ref8" style={{ display: 'list-item', marginLeft: '-14px' }}>
          [8] Gillaugh, D. L., Kaszynski, A. A., Brown, J. M., Beck, J. A., & Slater, J. C. (2019). Mistuning Evaluation Comparison Via As-Manufactured Models, Traveling Wave Excitation, and Compressor Rigs.{' '}
          <a
            href="https://doi.org/10.1115/1.4042079"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: '#3f51b5' }}
          >
            Journal of Engineering for Gas Turbines and Power, 141(6), 061006.
          </a>
        </ListItem>

      </List>


    </Box>
  );
};

export default AFRLCaseStudy;

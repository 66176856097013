import React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';

const BdcCaseStudy: React.FC = () => {
  return (
    <Box mt={8} mb={8} p={4} style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'justify' }}>

      <Typography variant="h4" py={1} align="center" gutterBottom>
        AFRL Digital Engineering Technology Transitioned to F119 and F135 Engine Maintenance Operations
      </Typography>

      <Typography variant="h5" py={1} align="center" gutterBottom>
        Incorporating Geometry into Blade Diagnostic's SmartBlend® System
      </Typography>

      <Box pb={2} sx={{ float: 'right', marginLeft: 2, marginTop: 1, width: '100%' }}>
        <img
          src="/case_studies/bdc-modal-response.webp"
          alt="Geometrically Sensitive Modes"
          style={{ width: '100%', borderRadius: '8px', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)' }}
        />

        <Typography variant="body2" sx={{ marginTop: 1 }} style={{ textAlign: 'center' }}>
          Geometrically Sensitive Blade Mode Shapes {' '}
          <a
            href="#ref1"
            style={{ textDecoration: 'none', color: '#3f51b5' }}
          >
            [1]
          </a>

        </Typography>

      </Box>

      <Typography variant="body1" paragraph>
        AFRL/RQT researchers recently developed digital engineering capabilities that are now in use by the AFLCMC’s Oklahoma City Air Logistics Complex to support F119 and F135 engine operations. The new capabilities are based on the AFRL developed FEMORPH software that creates digital models of as-manufactured and as-repaired machinery, which previously transitioned to Pratt & Whitney in 2018. In this new development, the FEMORPH software was integrated with depot inspection systems that measure both repaired geometries and vibratory response. This measurement system was developed under prior AFRL sponsored SBIR contracts with Blade Diagnostics Corporation's (BDC). BDC’s SmartBlend® system is a key enabler of tailored repair strategies for integrally bladed rotors (IBRs) and this partnership marks the latest milestone in FEMORPH’s expansion into advanced aerospace applications. Combining BDC’s proprietary experimental vibration analysis tools with FEMORPH’s capabilities in as-manufactured modeling and component-specific analysis has led to game-changing improvements to maintenance operations.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Accurate Digital Twins with FEMORPH
      </Typography>

      <Typography variant="body1" paragraph>
        FEMORPH provides a critical feature to BDC’s SmartBlend System by creating a full 3D digital model of the repaired blade by incorporating BDC's geometry measurements with FEMORPH's industry leading mesh metamorphosis algorithms. This enables tailored repair assessments through its integration with BDC's EzVIBES® vibration measurement system and FMM Predictor™. This holistic SmartBlend System provides:
      </Typography>

      <Box sx={{ float: 'right', marginLeft: 2, marginTop: 1, width: '300px' }}>
        <img
          src="/case_studies/bdc-response-graph.webp"
          alt="Blade Specific Response"
          style={{ width: '100%', borderRadius: '8px', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)' }}
        />
        <Typography variant="body2" sx={{ marginTop: 1 }} style={{ textAlign: 'center' }}>
          Blade Specific Response {' '}
          
          <a
            href="#ref2"
            style={{ textDecoration: 'none', color: '#3f51b5' }}
          >
            [2]
          </a>

        </Typography>

      </Box>

      <List
        sx={{
          listStyleType: 'disc',
          listStylePosition: 'inside',
          marginLeft: '-14px'
        }}
      >
        <ListItem sx={{ display: 'list-item' }} style={{ textAlign: 'justify'}}>Part-specific analysis of vibratory behavior and durability limits. By capturing the blade-to-blade variations, the Air Force can better predict the variation in high cycle fatigue due to both mistuning amplification as well as mode shape variation for key modes of interest.</ListItem>
        <ListItem sx={{ display: 'list-item' }} style={{ textAlign: 'justify'}}>Automated workflows for geometry assessment and repair feasibility studies. FEMORPH works with BDC's edge geometry in a fully automated digital workflow.</ListItem>
        <ListItem sx={{ display: 'list-item' }} style={{ textAlign: 'justify'}}>Advanced modeling of blended blade geometries that preserves the nominal geometry while updating the finite element model to match any new blends placed as part of the repair process.</ListItem>
        <ListItem sx={{ display: 'list-item' }} style={{ textAlign: 'justify'}}>Accurate prediction of post-repair performance through geometrically accurate as repaired models. Using finite element analysis in combination with the experimental IBR response, the Air Force can predict the peak responding blade and if it exceeds maximum amplification thresholds.</ListItem>
      </List>

      <Typography variant="body1" paragraph>
        The integration of FEMORPH into BDC’s technology ecosystem promises to increase fleet readiness through efficient repair processes. and reduce replacement costs by extending IBR usability. This will enable the USAF to precisely evaluate fatigue risk and enhance data collection to refine future repair methodologies.
      </Typography>

      <Typography variant="body1" paragraph>
        FEMORPH’s partnership with Blade Diagnostics Corporation represents a transformative step in blade health monitoring. By enabling tailored, part-specific repairs, FEMORPH complements BDC’s vision of advancing repair technologies, ensuring the safety, efficiency, and sustainability of aerospace operations.
      </Typography>

      <Typography variant="h4" py={1}>
        References
      </Typography>

      <List>
        <ListItem id="ref1" style={{ display: 'list-item', marginLeft: '-14px' }}>
          [1] Gillaugh, D. L., Kaszynski, A. A., Brown, J. M., Johnston, D. A., & Slater, J. C. (2018). Accurate Strain Gauge Limits Through Geometry Mistuning Modeling.{' '}
          <a
            href="https://doi.org/10.2514/1.B36849"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: '#3f51b5' }}
          >
            Journal of Propulsion and Power, 34(3), 1–8. (2018)
          </a>
        </ListItem>
        <ListItem id="ref2" style={{ display: 'list-item', marginLeft: '-14px' }}>
          [2] Gillaugh, D. L., Kaszynski, A. A., Brown, J. M., Beck, J. A., & Slater, J. C. (2019). Mistuning Evaluation Comparison Via As-Manufactured Models, Traveling Wave Excitation, and Compressor Rigs.{' '}
          <a
            href="https://doi.org/10.1115/1.4042079"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: '#3f51b5' }}
          >
            Journal of Engineering for Gas Turbines and Power, 141(6), 061006.
          </a>
        </ListItem>
      </List>
      
    </Box>
  );
};

export default BdcCaseStudy;
